import React, { MouseEvent, useState } from 'react';
import { GridItem, Image } from '@chakra-ui/react';

const Thumbnail: React.FC<{
  setNoThumbnails: React.Dispatch<React.SetStateAction<boolean>>;
  thumbnail: string;
  isActive: boolean;
  width?: string;
}> = React.memo(({ thumbnail, width, isActive, setNoThumbnails }) => (
  <Image
    p={3}
    key={thumbnail}
    src={thumbnail}
    width={width || '100%'}
    onError={() => setNoThumbnails(true)}
    _hover={{ cursor: 'pointer' }}
    border={isActive ? '1px solid' : 'none'}
    borderColor={isActive ? 'purple' : 'none'}
  />
));

const Thumbnails: React.FC<{
  thumbnails: string[];
  setActive: any;
  active: string;
  width?: string;
  as: any;
}> = ({ width, as, thumbnails, setActive, active }) => {
  const [noThumbnails, setNoThumbnails] = useState<boolean>(!thumbnails.length);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLImageElement;
    if (target.tagName === 'IMG') setActive(target.src);
  };

  return (
    <GridItem as={as} spacing={2} onClick={handleClick} overflowX="auto">
      {!noThumbnails &&
        thumbnails.map((thumbnail) => (
          <Thumbnail
            width={width}
            key={thumbnail}
            thumbnail={thumbnail}
            setNoThumbnails={setNoThumbnails}
            isActive={thumbnail === active}
          />
        ))}
    </GridItem>
  );
};

export default Thumbnails;
