import { HStack, useRadioGroup } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import Radio from '../radio';

export interface VariantsProps {
  values: string[];
  defaultValue: string;
  setCurrent: Dispatch<SetStateAction<any>>;
  name: string;
}

const Variants: React.FC<VariantsProps> = ({
  name,
  defaultValue,
  values,
  setCurrent,
}) => {
  const { getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value: string) => {
      setCurrent((current: any) => ({
        ...current,
        [name]: value,
      }));
    },
  });

  return (
    <HStack>
      {values.map((value) => (
        <Radio key={value} {...getRadioProps({ value })}>
          {value}
        </Radio>
      ))}
    </HStack>
  );
};

export default Variants;
