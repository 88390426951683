import React from 'react';
import { Tag, Flex } from '@chakra-ui/react';

const Tags: React.FC<{
  manufacturer: string;
  category: string;
}> = ({ manufacturer, category }) => (
  <Flex
    sx={{
      'span:not(:last-of-type)': {
        marginRight: '5px',
      },
    }}
  >
    <Tag
      mt={2}
      bg="purple"
      fontSize="11px"
      variant="solid"
      colorScheme="purple"
      textTransform="capitalize"
    >
      {manufacturer}
    </Tag>
    <Tag
      mt={2}
      bg="purple"
      fontSize="11px"
      variant="solid"
      colorScheme="purple"
      textTransform="capitalize"
    >
      {category}
    </Tag>
  </Flex>
);

export default Tags;
