import { Box, useRadio } from '@chakra-ui/react';
import React from 'react';

const Radio: React.FC<any> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        display="grid"
        placeItems="center"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        fontSize="sm"
        _checked={{
          bg: 'purple',
          color: 'white',
          borderColor: 'purple',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        height={37.5}
        px={4}
      >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {props.children}
      </Box>
    </Box>
  );
};

export default Radio;
