import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Grid,
  GridItem,
  Heading,
  VStack,
  Text,
  HStack,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useBreakpointValue,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import Icon from 'feather-icons-react';
import { PageProps, navigate } from 'gatsby';
import ReactImageFallback from 'react-image-fallback';
import { ArrowBackIcon } from '@chakra-ui/icons';
import pluralize from 'pluralize';
import { capitalcase, sentencecase } from 'stringcase';
import { useAtomValue } from 'jotai';

import Head from '../head';
import Tags from './tags';
import Layout from '../../layout';
import Thumbnails from './thumbnails';
import { SimilarProducts } from '../products';
import type { ProductWithSimilarProducts } from '../../types';
import noPreviewImage from '../../images/no-preview.png';
import Variants from './variants';
import { useMessageBird } from '../../hooks';
import * as atoms from '../../atoms';
import type { Currency } from './item';

const getPriceValues = (price: string) => {
  const entity = price.split(',').reduce((prices, price) => {
    const [currency, value] = price.split('-');

    return {
      ...prices,
      [currency.toLowerCase()]: +value,
    };
  }, {});

  return entity;
};

const getBuyNowMessage = (
  data: {
    price: number;
    name: string;
    currency: string;
  } & Record<string, string | number>,
) => {
  const { name, price, quantity: qty, currency, ...variant } = data;
  const quantity = Number(qty);
  const symbol = currency === 'usd' ? '$' : '';

  const variants = Object.entries(variant).reduce(
    (message, [key, value]) =>
      value
        ? `${message}
      ${sentencecase(key)}: ${value}`
        : message,
    '',
  );

  return `Hello,

    I want to place an order for ${pluralize(
      'piece',
      quantity,
      true,
    )} of ${capitalcase(name)}.
    ${variants}

    Total: ${symbol}${price}
  `;
};

const Product: React.FC<PageProps<object, ProductWithSimilarProducts>> = ({
  pageContext,
}) => {
  const {
    category,
    description,
    manufacturer,
    name,
    similarProducts,
    thumbnails,
    variants,
  } = pageContext;

  const isMediumScreen = useBreakpointValue({
    lg: false,
    md: true,
    base: true,
  });

  const isSmallScreen = useBreakpointValue({
    lg: false,
    md: false,
    base: true,
  });

  const [current, setCurrent] = useState<{
    [key: string]: number | string;
  }>(() => {
    const initial = variants.keys.reduce(
      (state, key, index) => {
        if (key !== 'prices') {
          const value = variants.values[0][index];
          return { ...state, [key]: value };
        }

        return state;
      },
      { quantity: 1 },
    );

    return initial;
  });
  const [preview, setPreview] = useState<string>(thumbnails[0]);
  const [price, setPrice] = useState<number>(0);
  const messageBird = useMessageBird();
  const currency = useAtomValue(atoms.currency);

  useEffect(() => {
    const { keys, values } = variants;
    const variantKeys = [...keys].splice(0, keys.length - 1);

    const variant =
      values.length === 1
        ? values[0]
        : values.find((value) =>
            variantKeys.every((key) => value.includes(`${current[key]}`)),
          );

    if (variant) {
      const priceString = variant[variant.length - 1];
      const prices = getPriceValues(priceString) as Record<Currency, number>;
      const price = prices[currency];
      setPrice(
        Number(price * +current.quantity).toFixed(2) as unknown as number,
      );
    }
  }, [current, variants]);

  const handleQuantityChange = (value: string) => {
    setCurrent((current) => ({
      ...current,
      quantity: +value,
    }));
  };

  const buyNow = useCallback(() => {
    const message = getBuyNowMessage({
      ...current,
      currency,
      price,
      name,
    });
    messageBird()?.startConversation(message);
  }, [current, messageBird, name, price, currency]);

  return (
    <Layout headerColorScheme="light">
      <Head title={`${sentencecase(name)} - Jamestown`} />

      <Box
        p={{
          lg: `5rem calc(var(--cp) + 1rem) 12rem`,
          base: `3rem calc(var(--cp) + 1rem)`,
          md: `6rem calc(var(--cp) + 1rem)`,
        }}
      >
        <Button
          mb={{ md: 10, base: 10 }}
          px={0}
          size="lg"
          variant="ghost"
          onClick={() => navigate(-1)}
          leftIcon={<ArrowBackIcon />}
          _hover={{
            bg: 'transparent',
          }}
        >
          Back
        </Button>

        <Grid
          gap={{ lg: 10, md: 20, base: 30 }}
          templateColumns={{
            lg: '80px 1fr 50%',
            md: '1fr 1fr',
            base: '1fr',
          }}
          alignItems="start"
        >
          {isSmallScreen && (
            <Box mb={10}>
              <Heading fontWeight={800} textTransform="capitalize">
                {name}
              </Heading>
              <Tags category={category} manufacturer={manufacturer} />
            </Box>
          )}

          {!isMediumScreen && (
            <Thumbnails
              as={VStack}
              active={preview}
              thumbnails={thumbnails}
              setActive={setPreview}
            />
          )}

          <GridItem
            bg="radial-gradient(rgba(27, 10, 75, 0.2) 35%, rgba(27, 10, 75, 0.001) 65%)"
            placeItems="center"
            display="grid"
          >
            <Box mb={{ lg: 0, md: 10, base: 5 }}>
              <ReactImageFallback
                src={thumbnails[0]}
                fallbackImage={noPreviewImage}
                initialImage={noPreviewImage}
                alt={name}
              />
            </Box>

            {isMediumScreen && (
              <Thumbnails
                as={HStack}
                width="70px"
                active={preview}
                setActive={setPreview}
                thumbnails={thumbnails}
              />
            )}
          </GridItem>

          <GridItem>
            {!isSmallScreen && (
              <>
                <Heading fontWeight={500} textTransform="capitalize">
                  {name}
                </Heading>
                <Tags category={category} manufacturer={manufacturer} />
              </>
            )}

            <Text fontWeight="bold" fontSize="2xl" mt={5}>
              {currency === 'usd' ? '$' : '₦'} {price}
            </Text>

            <Text mt={5} lineHeight={1.75}>
              {description}
            </Text>

            <Grid gap={5} mt={10} templateColumns="1fr 1fr">
              {variants.keys.map((key, index) => {
                if (key === 'prices') return null;

                const values = [
                  ...new Set(variants.values.map((variant) => variant[index])),
                ].filter(Boolean);

                if (!values.length) return null;

                const defaultValue = `${current?.[key]}`;

                return (
                  <GridItem
                    key={key}
                    as={VStack}
                    colSpan={2}
                    spacing={2}
                    align="left"
                  >
                    <Heading
                      mb={2}
                      size="16px"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {sentencecase(key)}
                    </Heading>

                    <Variants
                      name={key}
                      values={values}
                      setCurrent={setCurrent}
                      defaultValue={defaultValue}
                    />
                  </GridItem>
                );
              })}

              <GridItem as={VStack} spacing={2} align="left">
                <Heading size="16px" fontWeight={600} mb={2}>
                  Quantity
                </Heading>
                <HStack>
                  <NumberInput
                    min={1}
                    defaultValue={current.quantity}
                    onChange={handleQuantityChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
              </GridItem>
            </Grid>

            <HStack
              spacing={5}
              mt={{ md: '4rem', base: '3rem' }}
              sx={{ svg: { strokeColor: 'purple' } }}
            >
              {/* <Button
                size="md"
                bg="#fff"
                color="purple"
                fontSize="14px"
                borderWidth="1px"
                onClick={addToCart}
                borderColor="purple"
                _hover={{ bg: 'white', color: 'purple' }}
                leftIcon={<Icon icon="shopping-cart" size="16px" />}
              >
                Add To Cart
              </Button> */}

              <Button
                bg="purple"
                color="#fff"
                fontSize="14px"
                onClick={buyNow}
                _hover={{ bg: 'purple', color: '#fff' }}
                leftIcon={<Icon icon="shopping-bag" color="#fff" size="16px" />}
              >
                Buy Now
              </Button>
            </HStack>
          </GridItem>
        </Grid>

        <SimilarProducts products={similarProducts?.nodes || []} />
      </Box>
    </Layout>
  );
};

export default Product;
